<template>
    <div class="warp"
         v-loading="Loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="main">
            <el-row :gutter="12">
                <el-col :span="12">
                    <el-row :gutter="12">
                        <!-- 公共消息 -->
                        <el-col :span="24">
                            <div style="text-align: center;margin-top: 64px;">
                                <el-tooltip class="item" effect="dark" content="Bottom Left 提示文字" placement="bottom-start">
                                    <div slot="content" style="width: 300px;color:#c5b48e">
                                        <div style="text-align:center;margin-bottom: 4px;" >温馨提示</div>
                                        <p style="margin-bottom: 4px;">*团本每日角色限开团/参团{{info.config.num}}次，每日0点重置</p>
                                        <p style="margin-bottom: 4px;">*团本开放时间为：{{info.config.open}} <span style="color:#6b9408" v-if="info.config.open_time != ''">{{ info.config.open_time }}</span></p>
                                        <p>*参团前背包必须准备下面物品门票</p>
                                        <div style="margin:4px 0px 4px 19px">
                                            <img :src="info.config.items.ico_data"  style="width:28px;height: 28px;" />
                                            <span style="margin-left: 6px;">{{ info.config.items.name }}</span>
                                        </div>
                                        <p style="margin-bottom: 4px;">*队伍开团后，中途加入的成员，必须携带门票进入，进入队伍后门票自动扣除。</p>
                                        <p style="margin-bottom: 4px;">*其他玩法流程复核国服</p> 
                                    </div>
                                    <span class="el-icon-question"></span>
                                </el-tooltip> 
                            </div>
                            <div style="text-align: center;">
                                <div class="sqlb" v-if="info.team_tag == 0" @click="CreateGroupDisplay = true">创建团队</div>
                                <div class="sqlb" v-if="info.team_tag == 1" @click="ApplyGroupDisplay = true">申请({{ info.apply_log.length }})</div>
                                <div class="sqlb" v-if="info.team_tag == 1" @click="StartGroup">开始攻坚</div>
                                <div class="sqlb" v-if="info.team_tag == 1" @click="ApplyGroupDissolution">解散团本</div>
                                <div class="sqlb" v-if="info.team_tag == 2" @click="ApplyGroupLeave">退出团队</div>
                                <div class="sqlb"  @click="getInfo">刷新</div>
                            </div>
                            <div style="text-align: center;margin-top: 20px;margin-bottom:54px;">
                                <p>当前角色：<span>{{ info.charac_name }}</span></p>
                            </div>
                        </el-col>
                         <!-- 攻坚团队 -->
                        <el-col :span="24">
                            <div style="position: relative;top: 34.6px;margin-left: 16px;margin-right: 22px;">
                                <table style="background:#05111d;height: 248px;"  @contextmenu.prevent>
                                    <!-- 循环 -->
                                    <div v-for="(item, index) in info.team_data" :key="index">
                                        <div v-if="item.charac_no == info.charac_no">
                                            <el-dropdown  trigger="click" :disabled="true" style="color: #c5b48e;font-size: 12px;" @command="GroupFunction($event,item.id)">
                                                <tr class="tt" style="height: 20px;line-height: 22px;">
                                                    <td class="td-a">
                                                        <p style="margin-left:4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 223px;padding-right: 0px;margin-right: -123px;"><span v-if="item.state == 0"><i class="jd0"></i></span><span v-else-if="item.state == 1 && item.period == 1"><i class="jd1"></i></span><span v-else-if="item.state == 1 && (item.period == 2 || item.period == 3)"><i class="jd2"></i></span><span v-else-if="item.state == 2"><i class="jd3"></i></span>{{ item.name }}</p>
                                                    </td>
                                                    <td  style="width:64px;text-align: center;">
                                                        <span>（{{item.team_num}}/20）</span>
                                                    </td>
                                                </tr>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item  command="1">申请加入</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>  
                                        </div>
                                        <div v-else>
                                            <el-dropdown  trigger="click" :disabled="item.state == 2  ? true : false" style="color: #c5b48e;font-size: 12px;" @command="GroupFunction($event,item.id)">
                                                <tr class="tt" style="height: 20px;line-height: 22px;">
                                                    <td class="td-a">
                                                        <p style="margin-left:4px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 223px;padding-right: 0px;margin-right: -123px;"><span v-if="item.state == 0"><i class="jd0"></i></span><span v-else-if="item.state == 1 && item.period == 1"><i class="jd1"></i></span><span v-else-if="item.state == 1 && (item.period == 2 || item.period == 3)"><i class="jd2"></i></span><span v-else-if="item.state == 2"><i class="jd3"></i></span>{{ item.name }}</p>
                                                    </td>
                                                    <td  style="width:64px;text-align: center;">
                                                        <span>（{{item.team_num}}/20）</span>
                                                    </td>
                                                </tr>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item  command="1">申请加入</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>  
                                        </div>
                                    </div>
                                     <!-- 循环end -->
                                </table>  
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <div style="margin-top:74px;">
                        <table style="height: 412px;">
                            <div v-for="(item, index) in info.team_members_data" :key="index">
                                <el-dropdown  trigger="click" :disabled="item.charac_no == info.charac_no  ? true : false"  style="color: #c5b48e;font-size: 12px;"    @command="GroupFunction($event,item.id)">
                                    <tr class="tt" style="height: 20px;line-height: 22px;">
                                        <td  style="width:38px;text-align: center;">
                                            <span v-if="item.team == 0">单人</span>
                                            <span v-else>{{ item.team }}</span>
                                        </td>
                                        <td  style="width:68px;text-align: center;">
                                            <span>Lv{{ item.lv }}</span>
                                        </td>
                                        <td  style="width:80px;text-align: center;">
                                            <p><i v-if="item.identity == 1" class="dzbz"></i>{{ item.charac_name }}</p>
                                        </td>
                                        <td  style="width:78px;text-align: center;">
                                            <span>{{ item.job }}</span>
                                        </td>
                                        <td  style="width:68px;text-align: center;">
                                            <span v-if="info.team_zt == 0" style="color:#e00000;">{{ item.tiems_zt }}</span>
                                            <span v-else-if="info.team_zt == 1" style="color:#e00000;"><span v-if="item.room <= 0">0 / 1</span><span v-else-if="item.room > 0">1 / 1</span></span>
                                            <span v-else-if="info.team_zt == 2" style="color:#e00000;">已结束</span>
                                        </td>
                                    </tr>
                                    
                                    <el-dropdown-menu slot="dropdown"  >
                                        <!-- <el-dropdown-item command="2">邀请交易</el-dropdown-item> -->
                                        <el-dropdown-item command="3" v-if="info.team_tag == 1">踢出队伍</el-dropdown-item>
                                        <!-- <el-dropdown-item command="4">查看信息</el-dropdown-item> -->
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            
                        </table>
                    </div>
                </el-col>
            </el-row> 

            <div class="pop">
                <div class="alert_bg pop-buy" style="" v-if="CreateGroupDisplay">
                    <div class="alert_main">
                        <div class="alert_title"><span>队伍创建</span></div>
                        <div class="alert_con">
                            <div style="margin-top:16px;">
                                <input type="text" v-model="CreateGroup_form.name" style="border: 1px solid #2a2a2a;width:92%;padding:0px 2px;color:#877b62;" placeholder="请输入需要创建的名称" />
                            </div>
                            <div style="text-align: center; padding: 10px 0px">
                                <div class="btn" style="margin: 0px 0px; display: inline-block"  @click="CreateGroup">创建</div>
                                <div class="btn" style="margin: 0px 0px; display: inline-block;margin-left:8px;" @click="CreateGroupDisplay = false">取消</div>
                            </div> 
                        </div>
                        
                    </div>
                </div>

                <div class="alert_bg pop-buy" v-if="ApplyGroupDisplay">
                    <div class="alert_main" style="width: 540px;margin-top: 136px;">
                        <i style="position: relative;left: 256px;top: 21px;z-index: 20;font-size: 18px;color: #bc9b9b;"  @click="ApplyGroupDisplay = false">x</i>
                        <div class="alert_title"><span>申请列表</span></div>
                        <div class="alert_con">
                            <div style="margin-top:10px;">
                                <div class="tr" style="background-size:100%;color:#c5b48e;">
                                    <span class="th-a" style="width: 270px;">昵称</span><span style="width:80px">Lv</span><span style="width:107px">职业</span><span style="width:80px;">操作</span>
                                </div>
                                <table style="background:#232323;height: 200px;color:#c5b48e;">
                                    <tr class="tt"  v-for="(item, index) in info.apply_log" :key="index">
                                        <td style="width:260px;text-align: left;padding-left: 6px;">{{ item.charac_name }}</td>
                                        <td style="width:75px;position: relative;left: 8px;">{{ item.lv }}</td>
                                        <td style="width:75px;position: relative;left: 28px;">{{ item.job }}</td>
                                        <td style="width:77px;color:#6b9408;position: relative;left: 36px;">
                                            <span style="margin-right: 8px;" @click="ApplyGroupAgree(1,item.id)">同意</span>
                                            <span @click="ApplyGroupAgree(2,item.id)">拒绝</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="alert">
                <!-- 弹出框 -->
                <div class="alert_bg" v-if="MessageDisplay">
                    <div class="alert_main">
                        <div class="alert_title"><span>提示</span></div>
                        <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div class="btn"
                                @click="CloseAlert">关闭</div>
                        </div>
                        </div>
                        <div class="alert_bottom"><span></span></div>
                    </div>
                </div>
                <div class="alert_bg" v-if="SuccessDisplay">
                    <div class="alert_main">
                        <div class="alert_title"><span>提示</span></div>
                        <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div class="btn"
                                @click="SuccessAlert">关闭</div>
                        </div>
                        </div>
                        <div class="alert_bottom"><span></span></div>
                    </div>
                </div>
                 <!-- 登陆框 -->
                <div class="alert_bg" v-if="LoginDisplay">
                    <div class="alert_main"
                        style="margin-top: 166px; width: 350px">
                        <div class="alert_title"><span>绑定|切换</span></div>
                        <div class="alert_con">
                        <div class="alert_com">
                            <p style="margin-bottom: 6px; display: flex">
                            <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input type="text"
                                    v-model="login_info.username"
                                    class="service__input" />
                            </p>
                            <p style="margin-bottom: 6px; display: flex">
                            <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input type="password"
                                    v-model="login_info.password"
                                    class="service__input" />
                            </p>
                            <p style="margin-bottom: 6px; display: flex">
                            <span style="padding: 0px 0px 0px 42px">绑定角色：</span>
                            <select v-model="login_info.role"
                                    style="width: 174px">
                                <option v-for="(itmec, i) in role_data"
                                        :key="i"
                                        :value="itmec.charac_no">
                                {{ itmec.charac_name }}
                                </option>
                            </select>
                            <span style="background: #7478e4;padding: 3px 10px;margin-left: 2px;border-radius: 4%;" @click="getRole">获取</span>
                            </p>
                            <div>
                            <span style="padding: 0px 0px; color: #f90000"><span style="display: inline-block; padding: 0px 0px">{{
                                                        LoginError
                                                        }}</span></span>
                            </div>
                            <div style="text-align: center; padding: 10px 0px">
                            <div class="btn"
                                @click="login"
                                style="margin: 0px 0px; display: inline-block">
                                绑定
                            </div>
                            <div class="btn"
                                @click="LoginDisplay = false"
                                style="margin: 0px 0px; display: inline-block">
                                取消
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="alert_bottom"><span></span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
  //import $ from "jquery";
import Vue from "vue";
  export default {
    name: "Group",
    data () {
      return {
        CreateGroupDisplay:false,
        ApplyGroupDisplay:false,
        MessageDisplay: false, //弹出框显示
        Loading: false,
        SuccessDisplay: false,//成功弹出框
        LoginDisplay: false, //登陆弹出框
        info: {
            m_id:0,
            charac_no:0,
            charac_name: '', //当前绑定角色
            team_data:[],
            team_members_data:[],
            team_tag:0,
            team_zt:0,
            apply_log:[],
            config:{
                open:'',
                open_time:'',
                num:0,
                items:{
                    code:0,
                    name:'',
                    ico_data:''
                },
            },
        },
        CreateGroup_form:{
            name:''
        },
        login_info: {
          username: "",
          password: "",
          role: "",
        },
      };
    },
    created () {
        this.getInfo();
    },
    methods: {
         //登陆
        login () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/PlugLogin",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                username: this.login_info.username,
                password: this.login_info.password,
                role: this.login_info.role,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                this.LoginDisplay = false;
                this.getInfo();
                } else {
                this.LoginError = response.data.msg;
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        //获取角色
        getRole () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/getRole",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                username: this.login_info.username,
                password: this.login_info.password,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                this.LoginError = "";
                this.role_data = response.data.info;
                } else {
                this.LoginError = response.data.msg;
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        //获取信息
        getInfo(){
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/getGroupInfo",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.info = response.data.info;
                } else {
                this.PopMessage(true, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        //创建队伍
        CreateGroup(){
            if(this.Loading == true){
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/CreateGroup",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                name:this.CreateGroup_form.name,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.CreateGroup_form.name = "";
                    this.CreateGroupDisplay = false;
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        GroupFunction(cmd,Id){
            if(cmd == "1"){
               this.ApplyGroupTeam(Id);
            }
            if(cmd == "2" || cmd == "3" || cmd == "4"){
                this.GroupKickedOut(cmd,Id);
            }
        },
        //申请加入队伍
        ApplyGroupTeam(ids){
            if(this.Loading == true){
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/ApplyGroupTeam",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                id:ids,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            }); 
        },
        //同意/拒绝
        ApplyGroupAgree(type,Ids){
            if(this.Loading == true){
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/ApplyGroupAgree",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                id:Ids,
                type:type,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            }); 
        },
        //解散
        ApplyGroupDissolution(){
            if(this.Loading == true){
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/ApplyGroupDissolution",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            }); 
        },
        //退出队伍
        ApplyGroupLeave(){
            if(this.Loading == true){
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/ApplyGroupLeave",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            }); 
        },
        //队伍菜单
        GroupKickedOut(type,ids){
            if(this.Loading == true){
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/GroupKickedOut",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                id:ids,
                type:type,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            }); 
        },
        //开始打团
        StartGroup(){
            if(this.Loading == true){
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/group/StartGroup",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            }); 
        },
         //弹出框状态
        PopMessage (type, msg) {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.MessageMsg = msg;
            if (type == false) {
            this.MessageDisplay = true;
            } else {
            this.SuccessDisplay = true;
            }
        },
        SuccessAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.getInfo();
        },
        //关闭窗口
        CloseAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
        },
    },
  };
  </script>
    <style scoped>
  @import "../../../public/static/plug/group/static/css/game.css";
  </style>

<style lang="less">
.el-row{
    margin-left: 0px  !important;
    margin-right: 0px  !important;
}
.el-col{
    padding-left:0px !important;
    padding-right:0px !important;
}
.el-dropdown-menu{
    border: 1px solid #2c2b2b;
    padding:2px 0px !important;
}
.el-dropdown-menu__item{
    line-height:20px;
    font-size:12px;
}
</style>
    <style>
  #root,
  body,
  html {
    min-width: 0px;
  }
  .el-cascader-node {
      height:20px !important;   
  }
  
  .el-cascader__dropdown{
    background: #101010  !important;
    border: 1px solid #616161  !important;
  }
  </style>